'use client'

import { useTranslations } from 'next-intl'
import { useMemo } from 'react'
import styled from 'styled-components'

import type { FontSize } from '@fortum/elemental-ui'
import { Box, ContentText, fontWeights } from '@fortum/elemental-ui'

import { useContractTemplateCostCalculator } from '@/open-web/hooks/useContractTemplateCostCalculator/useContractTemplateCostCalculator'
import type { CampaignConfigurationEntry } from '@/shared/contentful/types'
import { countryConfig } from '@/shared/countryConfig'
import { useTheme } from '@/shared/hooks/useTheme'
import type { Theme } from '@/shared/providers/theme'
import type {
  EnrichedContractProduct,
  EnrichedContractTemplate,
} from '@/shared/services/campaignDataResolver'
import { useGlobalStore } from '@/shared/store/provider'
import type { CustomerType } from '@/shared/store/slices/selectedContractSlice'

import { getSelectedAddonsTariffNos } from '../PriceBreakdown/utils'
import type { PriceBreakdownInfoIconProps } from '../PriceBreakdownInfoIcon'
import { PriceBreakdownInfoIcon } from '../PriceBreakdownInfoIcon'

export type PriceProps = {
  contractTemplate: EnrichedContractTemplate
  contractProduct: EnrichedContractProduct
  campaignConfiguration: CampaignConfigurationEntry

  mode?: 'full' | 'minimal'
  theme?: 'light' | 'dark'
  priceSize?: FontSize
  priceBreakdownMode?: PriceBreakdownInfoIconProps['mode']
  shouldShowPricePlaceholder?: boolean
}

const defineTheme = (colors: Theme['colors'], theme: PriceProps['theme'] = 'light') => {
  switch (theme) {
    case 'light':
      return {
        title: colors.textPrimary,
        price: colors.textPrimary,
        crossedPrice: colors.textSecondary,
        detailedPrice: colors.textPrimary,
        noPriceInfo: colors.textPositive,
      }

    case 'dark':
      return {
        title: colors.textLightOnDark,
        price: colors.textLightOnDark,
        crossedPrice: colors.textLightOnDark,
        detailedPrice: colors.textLightOnDarkAccent,
        noPriceInfo: colors.textLightOnDarkAccent,
      }
  }
}

/**
 * Price Component
 *
 * Needed to show price in all cases, including
 * price fetcing and styling.
 *
 * By default, renders:
 * - estimated monthly cost with applied discounts
 * - estimated monthly cost without discounts
 * - electricity price
 */
export const Price: React.FC<PriceProps> = ({
  contractTemplate,
  campaignConfiguration,
  contractProduct,
  mode = 'full',
  theme = 'light',
  priceBreakdownMode = 'default',
  priceSize = 'xl',
  shouldShowPricePlaceholder,
}) => {
  if (
    contractTemplate.customerType !== 'ENTERPRISE' &&
    contractTemplate.customerType !== 'PRIVATE'
  ) {
    throw new Error(
      `${contractTemplate.templateNo} has UNKNOWN customerType. Contract has bad configuration. Can not be sold!`,
    )
  }

  const { estimatedConsumption, deliveryArea } = useGlobalStore((store) => store.housingInfo)
  const startDate = useGlobalStore((store) => store.checkoutData.delivery.startDate)
  const customerType = contractTemplate.customerType.toLocaleLowerCase() as CustomerType
  const selectedContract = useGlobalStore((state) => state.selectedContract)
  const { selectedAddons } = selectedContract[customerType]
  const { colors } = useTheme()
  const tPurchaseFlow = useTranslations('purchaseFlow')
  const tAriaLabels = useTranslations('ariaLabels')
  const selectedAddonsTariffNos = useMemo(
    () => getSelectedAddonsTariffNos(contractTemplate, selectedAddons),
    [contractTemplate, selectedAddons],
  )
  const data = useContractTemplateCostCalculator({
    contractTemplate,

    selectedAddonsTariffNos,
    area: deliveryArea,
    estimatedYearlyConsumption: estimatedConsumption,
    startDate,
  })
  const colorConfiguration = defineTheme(colors, theme)
  const defaultUnit = `${countryConfig.currencySign}/${tPurchaseFlow('monthUnit')}`

  if (!data) {
    if (shouldShowPricePlaceholder) {
      return (
        <Box display="flex" flexDirection="column">
          <ContentText size="s" color={colors.textLink} visibility="hidden">
            {tPurchaseFlow('priceTitle')}
          </ContentText>
          <ContentText
            size={priceSize}
            fontWeight={fontWeights.medium}
            color={colorConfiguration.price}
            data-testid="no-price"
          >
            -- {defaultUnit}
          </ContentText>
          <ContentText
            size="xs"
            color={colorConfiguration.noPriceInfo}
            textWrap="word"
            data-testid="no-price-info"
          >
            {tPurchaseFlow('noPriceInfo')}
          </ContentText>
        </Box>
      )
    }

    return null
  }

  const {
    estimatedMonthlyCostWithDiscounts: withDiscount,
    estimatedMonthlyCost: withoutDiscount,
    energyPriceSumWithDiscounts,
  } = data

  const monthlyFinalString = `${withDiscount.priceInclVat.toFixed(2)} ${withDiscount.priceUnit}`
  const monthlyFullString =
    withDiscount.priceInclVat === withoutDiscount.priceInclVat
      ? undefined
      : `${withoutDiscount.priceInclVat.toFixed(2)} ${withoutDiscount.priceUnit}`
  const electricityPriceString = `${energyPriceSumWithDiscounts.priceInclVat.toFixed(2)} ${energyPriceSumWithDiscounts.priceUnit}`

  return (
    <Box>
      {mode === 'full' && (
        <PriceBreakdownInfoIcon
          mode={priceBreakdownMode}
          theme={theme}
          contractTemplate={contractTemplate}
          campaignConfiguration={campaignConfiguration}
          contractProduct={contractProduct}
        >
          <ContentText size="s" color="inherit">
            {tPurchaseFlow('priceTitle')}
          </ContentText>
        </PriceBreakdownInfoIcon>
      )}
      <ContentText
        size={priceSize}
        fontWeight={fontWeights.medium}
        color={colorConfiguration.price}
        aria-label={monthlyFullString ? tAriaLabels('discountPrice') : tAriaLabels('price')}
        data-testid="contract-price"
      >
        {monthlyFinalString}
      </ContentText>

      {monthlyFullString && (
        <CrossedOutText
          aria-label={tAriaLabels('originalPrice')}
          size="s"
          data-testid="crossed-out-price"
          color={colorConfiguration.crossedPrice}
        >
          {monthlyFullString}
        </CrossedOutText>
      )}
      <ContentText size="xs" data-testid="price-per-KW" color={colorConfiguration.detailedPrice}>
        {tPurchaseFlow('priceEnergyTitle')}: {electricityPriceString}
      </ContentText>
    </Box>
  )
}

const CrossedOutText = styled(ContentText)`
  text-decoration: line-through;
`
