'use client'

import { useTranslations } from 'next-intl'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import styled from 'styled-components'

import {
  Box,
  ButtonPill,
  ContentText,
  IconArrowRight,
  IconEdit,
  fontWeights,
  px2rem,
  shadows,
  spacing,
  styles,
} from '@fortum/elemental-ui'

import { Icon } from '@/shared/components/Icon'
import type { LinkEntry } from '@/shared/contentful/types'
import { getLinkEntryUrl } from '@/shared/contentful/utils'
import { useTheme } from '@/shared/hooks/useTheme'
import { Link, useRouter } from '@/shared/navigation'
import { useGlobalStore } from '@/shared/store/provider'
import { stepEvent } from '@/shared/utils/sendGTMEvent'

import { AdditionalInfoForm } from './AdditionalInfoForm'
import { HousingSelect } from './HousingSelect'
import { Input } from './Input'
import type { HousingOptions, QuickSearchForm } from './types'
import { QUICK_SEARCH_CONFIG } from './utils'

export type QuickSearchProps = {
  layout?: 'vertical' | 'responsive'
  primaryLink?: LinkEntry | null
}

export const QuickSearch: React.FC<QuickSearchProps> = ({ layout = 'responsive', primaryLink }) => {
  const [postalCode, setPostalCode] = useState<string>()
  const [streetAddress, setStreetAddress] = useState<string>()
  const [isAdditionalFormVisible, setIsAdditionalFormVisible] = useState(false)
  const [isPriceAreaError, setIsPriceAreaError] = useState(false)
  const [place, setPlace] = useState<string>()
  const [isConsumptionInputManual, setIsConsumptionInputManual] = useState(false)
  const t = useTranslations('quickSearchForm')
  const l = useTranslations('quickSearchForm.labels')
  const router = useRouter()
  const { colors } = useTheme()
  const flexDirection = layout === 'vertical' ? 'column' : { default: 'column', l: 'row' }

  const {
    postalCode: priceAreaPostalCode,
    housingSize,
    housingType,
    estimatedConsumption,
    deliveryArea,
    _isLoading,
    isMultipleAreas,
    setHousingSize,
    setHousingType,
    setEstimatedConsumption,
    setDeliveryArea,
    setPostalCode: setPriceAreaPostalCode,
    requestDeliveryArea,
  } = useGlobalStore((state) => state.housingInfo)

  const form = useForm<QuickSearchForm>({
    mode: 'onTouched',
    values: {
      postalCode: postalCode || '',
      streetAddress: streetAddress || '',
      city: place || '',
      housingSize: housingSize ? String(housingSize) : '',
      housingType,
      estimatedConsumption: estimatedConsumption ? String(estimatedConsumption) : '',
    },
  })

  const resetInnerState = () => {
    setPlace(undefined)
    setStreetAddress(undefined)
    setIsAdditionalFormVisible(false)
    setIsPriceAreaError(false)
  }

  const onChange = (name: keyof QuickSearchForm, value: string) => {
    switch (name) {
      case 'postalCode':
        setPostalCode(value)
        resetInnerState()
        if (priceAreaPostalCode) {
          setPriceAreaPostalCode(undefined)
        }
        if (deliveryArea) {
          setDeliveryArea(undefined)
        }
        break

      case 'housingSize':
        setHousingSize(+value || undefined)
        break

      case 'housingType':
        setHousingType(value as HousingOptions)
        break

      case 'estimatedConsumption':
        setEstimatedConsumption(+value || undefined)
        break

      case 'city':
        setPlace(value)
        if (deliveryArea) {
          setDeliveryArea(undefined)
        }
        break

      case 'streetAddress':
        setStreetAddress(value)
        if (deliveryArea) {
          setDeliveryArea(undefined)
        }
        break
    }
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (
        !Boolean(form.formState.errors.postalCode) &&
        !deliveryArea &&
        postalCode &&
        postalCode.length === 5 &&
        ((!streetAddress && !place) || (streetAddress && place))
      ) {
        setIsPriceAreaError(false) //reset error before refetch
        requestDeliveryArea(postalCode, streetAddress, place).catch(() => {
          setIsPriceAreaError(true)
        })
      }
    }, 1500)

    return () => clearTimeout(timeout)
  }, [postalCode, streetAddress, place, requestDeliveryArea])

  useEffect(() => {
    if (priceAreaPostalCode) {
      setPostalCode(priceAreaPostalCode)
      resetInnerState()
    }
  }, [priceAreaPostalCode])

  useEffect(() => {
    if (isMultipleAreas) {
      setIsAdditionalFormVisible(isMultipleAreas)
      setDeliveryArea(undefined)
    }
  }, [isMultipleAreas])

  return (
    <Box borderRadius={spacing.xxxs} backgroundColor={colors.backgroundPrimary}>
      <FormProvider {...form}>
        <form>
          <Box
            display="flex"
            flexDirection="column"
            gap={{ default: spacing.xxs, s: spacing.xs }}
            p={{ default: spacing.xxs, s: spacing.xs }}
            boxShadow={shadows.m}
            borderRadius={spacing.xxxs}
          >
            <Box
              display="flex"
              flexDirection={flexDirection}
              flexWrap="wrap"
              gap={{ default: spacing.xxs, s: spacing.xs, l: spacing.xxs }}
            >
              <Input
                {...QUICK_SEARCH_CONFIG.postalCode}
                texts={{
                  topText: l('postalCode.topText'),
                  label: l('postalCode.label'),
                  errorMessage: l('postalCode.errorMessage'),
                }}
                onChange={onChange}
                isLoading={_isLoading}
                isError={isPriceAreaError}
                isSuccess={Boolean(deliveryArea)}
              />
              {isAdditionalFormVisible && <AdditionalInfoForm onChange={onChange} />}
              <HousingSelect
                {...QUICK_SEARCH_CONFIG.housingType}
                handleSelect={onChange}
                texts={{
                  topText: l('housingType.topText'),
                  label: l('housingType.label'),
                  errorMessage: l('housingType.errorMessage'),
                }}
              />
              <Input
                {...QUICK_SEARCH_CONFIG.housingSize}
                onChange={onChange}
                texts={{
                  topText: l('housingSize.topText'),
                  label: l('housingSize.label'),
                  errorMessage: l('housingSize.errorMessage'),
                }}
              />
            </Box>
            {(estimatedConsumption || primaryLink || isConsumptionInputManual) && (
              <Box
                display="flex"
                flexDirection={flexDirection}
                gap={{ default: spacing.xxs, s: spacing.xs, l: spacing.xxs }}
                minHeight={{ default: 'auto', l: px2rem(99) }}
              >
                {housingType &&
                  (isConsumptionInputManual ? (
                    <Input
                      {...QUICK_SEARCH_CONFIG.estimatedConsumption}
                      texts={{
                        topText: l('estimatedConsumption.topText'),
                        label: l('estimatedConsumption.label'),
                        errorMessage: l('estimatedConsumption.errorMessage'),
                      }}
                      onChange={onChange}
                    />
                  ) : (
                    <Box display="flex" flexDirection="column" gap={spacing.xxxs} flex="1">
                      <ContentText color={colors.textSecondary}>
                        {l('estimatedConsumption.topText')}
                      </ContentText>
                      <Box display="flex" alignItems="baseline" gap={spacing.xxxs}>
                        <ConsumptionValueText
                          color={colors.textPositive}
                          fontWeight={fontWeights.medium}
                          data-testid="quick-search-form-consumption-value"
                        >
                          {estimatedConsumption}
                        </ConsumptionValueText>
                        <ContentText>{t('consumptionUnit')}</ContentText>
                        <ButtonPill
                          size="s"
                          status="plain"
                          fontSize="1rem"
                          rightIcon={<Icon icon={IconEdit} />}
                          onClick={() => setIsConsumptionInputManual(true)}
                          data-testid="quick-search-enable-consumption-edit"
                        >
                          {t('consumptionChangeButtonLabel')}
                        </ButtonPill>
                      </Box>
                    </Box>
                  ))}
                {primaryLink && (
                  <Box
                    display="flex"
                    flexDirection={{ default: 'column', l: 'row' }}
                    flex="2"
                    alignItems={{ default: 'center', l: 'flex-end' }}
                    justifyContent="flex-end"
                    mt={{ default: spacing.xxs, s: spacing.xs, l: spacing.none }}
                    gap={{ default: spacing.xxs, s: spacing.xs, l: spacing.xxs }}
                  >
                    <ButtonPill
                      tag={Link}
                      onClick={(event) => {
                        event.preventDefault()
                        router.push(getLinkEntryUrl(primaryLink))
                        stepEvent('find_contract', {
                          housing_type: housingType,
                          size: housingSize,
                          est_consumption: estimatedConsumption,
                        })
                      }}
                      data-testid="find-contracts-button"
                      rightIcon={<IconArrowRight size={24} />}
                    >
                      {primaryLink.label}
                    </ButtonPill>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </form>
      </FormProvider>
    </Box>
  )
}

const ConsumptionValueText = styled(ContentText)`
  font-size: 2.25rem;

  ${styles.breakpointMax.l} {
    font-size: 1.8125rem;
  }

  ${styles.breakpointMax.s} {
    font-size: 1.4375rem;
  }
`
