'use client'

import { useTranslations } from 'next-intl'
import type React from 'react'
import { useController } from 'react-hook-form'

import { Box, ContentText, IconInfo, spacing } from '@fortum/elemental-ui'

import { Icon } from '@/shared/components/Icon'
import { useTheme } from '@/shared/hooks/useTheme'

import { StyledInput } from '../../Checkout/styled'
import type { QuickSearchForm } from '../types'
import { QUICK_SEARCH_CONFIG } from '../utils'

type AdditionalInfoFormProps = {
  onChange: (name: keyof QuickSearchForm, value: string) => void
}

export const AdditionalInfoForm: React.FC<AdditionalInfoFormProps> = ({ onChange }) => {
  const t = useTranslations('quickSearchForm')
  const { streetAddress, city } = QUICK_SEARCH_CONFIG
  const { colors } = useTheme()

  const {
    field: { ref: streetNameRef, ...streetNameFieldProps },
    fieldState: streetNameFieldState,
  } = useController(streetAddress)
  const {
    field: { ref: cityRef, ...cityFieldProps },
    fieldState: cityFieldState,
  } = useController(city)

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexBasis="100%"
      order={{ default: 0, l: 1 }}
      gap={spacing.xxs}
      p={spacing.xxs}
      borderRadius="0.25rem"
      backgroundColor={colors.backgroundWarning}
      data-testid="quick-search-additional-info-form"
    >
      <Box display="flex" gap={spacing.xxs} alignItems="flex-start">
        <Icon icon={IconInfo} />
        <ContentText flex="1">{t('additionalInfoDisclaimer')}</ContentText>
      </Box>
      <Box display="flex" flexDirection={{ default: 'column', l: 'row' }} gap={spacing.xxs}>
        <StyledInput
          {...streetNameFieldProps}
          label={t('labels.streetAddress.label')}
          errorMessage={t('labels.streetAddress.errorMessage')}
          inputRef={streetNameRef}
          error={Boolean(streetNameFieldState.error)}
          data-testid="quick-search-form-street-name"
          onChange={(e) => {
            onChange(streetNameFieldProps.name, e.target.value)
          }}
        />
        <StyledInput
          {...cityFieldProps}
          label={t('labels.city.label')}
          errorMessage={t('labels.city.errorMessage')}
          inputRef={cityRef}
          error={Boolean(cityFieldState.error)}
          data-testid="quick-search-form-city"
          onChange={(e) => {
            onChange(cityFieldProps.name, e.target.value)
          }}
        />
      </Box>
    </Box>
  )
}
